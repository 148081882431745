import axios from '../axios';

export const all = () => {
  return axios.get('/discount-codes');
};

export const show = (id) => {
  return axios.get(`/discount-codes/${id}`);
};

export const create = (payload) => {
  return axios.post('/discount-codes', payload);
};

export const edit = (id, payload) => {
  return axios.put(`/discount-codes/${id}`, payload);
};

export const destroy = (id) => {
  return axios.delete(`/discount-codes/${id}/destroy`);
};
