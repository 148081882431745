<template>
  <b-card class="p-1">
    <div class="d-flex justify-content-between align-items-center mb-1">
      <b-form-group class="w-50 mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Wyszukaj po nazwie, cenie" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"> Wyczyść </b-button>
          </b-input-group-append>
        </b-input-group>

        <b-form-group class="mt-2">
          <b-form-checkbox v-model="showNewsletter"> Pokaż kody newsletter </b-form-checkbox>
        </b-form-group>
      </b-form-group>
      <div class="d-flex flex-gap">
        <b-button
          :to="{ name: 'discount-codes-create' }"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="btn">
          Dodaj
        </b-button>
      </div>
    </div>
    <b-table
      responsive
      bordered
      striped
      hover
      :items="discountCodesToShow"
      :fields="fields"
      :filter="filter"
      :filter-included-fields="filterOn">
      <template #cell(actions)="row">
        <div class="d-flex flex-column align-items-center">
          <b-button
            :to="{ name: 'discount-codes-edit', params: { id: row.item.id } }"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="btn table-btn"
            size="sm">
            Edytuj
          </b-button>
          <b-button class="mt-1 table-btn" variant="danger" size="sm" @click="destroy(row.item)"> Usuń </b-button>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import NotificationMixin from '@/mixins/NotificationMixin';

import { all, destroy } from '@/http/sales/discount-codes';

export default {
  mixins: [NotificationMixin],

  data() {
    return {
      discountCodes: [],
      showNewsletter: false,
      filter: '',
      filterOn: [],
      fields: [
        { key: 'code', label: 'Nazwa', sortable: true },
        {
          key: 'type',
          label: 'Typ',
          sortable: true,
          formatter: (value) => {
            switch (value) {
              case 'percent':
                return 'Procentowy';
              case 'amount':
                return 'Kwotowy';
              case 'delivery':
                return 'Na dostawę';
              case 'newsletter':
                return 'Newsletter';
            }
          },
        },
        { key: 'is_active', label: 'Aktywny', sortable: true, formatter: (value) => (value ? 'Tak' : 'Nie') },
        {
          key: 'value',
          label: 'Wartość',
          sortable: true,
          formatter: (value, field, row) => {
            if (['percent', 'delivery'].includes(row.type)) {
              return `${value}%`;
            }

            return `${value} zł`;
          },
        },
        { key: 'amount', label: 'Liczba', sortable: true },
        { key: 'count', label: 'Użycia', sortable: true },
        { key: 'expired_date', label: 'Ważny do', sortable: true },
        { key: 'actions', label: 'Akcje', class: 'w-25' },
      ],
    };
  },

  async mounted() {
    await this.fetchDiscountCodes();
  },

  computed: {
    discountCodesToShow() {
      if (this.showNewsletter) {
        return this.discountCodes;
      }

      return this.discountCodes.filter((discountCode) => discountCode.type !== 'newsletter');
    },
  },

  methods: {
    async fetchDiscountCodes() {
      try {
        const { data } = await all();

        this.discountCodes = data.data;
      } catch (error) {
        this.showErrorNotification(
          'Problem z pobraniem danych',
          'Wystąpił błąd podczas pobierania kodów rabatowych. Skontaktuj się ze swoim developerem.',
        );
      }
    },
    destroy(discountCode) {
      this.$swal({
        title: 'Jesteś pewny?',
        text: 'Czy chcesz usunąć ten kod rabatowy?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Usuń',
        cancelButtonText: 'Anuluj',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          destroy(discountCode.id)
            .then(() => {
              this.showSuccessNotification('Kod rabatowy usunięty', 'Kod rabatowy został pomyślnie usunięty.');
              this.fetchDiscountCodes();
            })
            .catch(() => {
              this.showErrorNotification(
                'Problem z usunięciem kodu rabatowego',
                'Wystąpił błąd podczas usuwania kodu rabatowego. Skontaktuj się ze swoim developerem.',
              );
            });
        }
      });
    },
  },
};
</script>
